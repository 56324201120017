import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import Button from '../button';
import MoreIcon from '../icons/more-icon';
import withFontClassName from '../../hoc/with-font-class-name';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import styles from './profile-actions-button.scss';
import { getThemeTextColor, getThemeBorderColor } from '../../services/get-theme-style';

const POSITION_RIGHT = 'right';
const POSITION_CENTER = 'center';

const ICON_RIGHT_OFFSET = 22;

class ProfileActionsButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      position: props.position || POSITION_CENTER,
      isMenuVisible: false,
    };
  }

  setActionsContainer = (node) => {
    this.actionsContainer = node;
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.hideComponent);
  }

  reposition = () => {
    if (!this.actionsContainer) {
      return;
    }

    const { position } = this.state;
    const trigger = findDOMNode(this).getBoundingClientRect();
    const actionsRect = findDOMNode(this.actionsContainer).getBoundingClientRect();
    const positionOffset = ICON_RIGHT_OFFSET - Math.ceil(trigger.width / 2);

    if (position === POSITION_CENTER && actionsRect.right > window.innerWidth) {
      this.setState({
        position: POSITION_RIGHT,
        positionOffset,
      });
    }

    if (position === POSITION_RIGHT) {
      this.setState({
        positionOffset,
      });
    }
  };

  handleClick = (event) => {
    if (this.state.isVisible) {
      this.hideComponent();
    } else {
      this.showComponent();
    }
    event.preventDefault();
    event.stopPropagation();
  };

  showComponent() {
    document.addEventListener('click', this.hideComponent);
    this.setState(
      {
        isMenuVisible: true,
      },
      this.reposition,
    );
  }

  hideComponent = () => {
    document.removeEventListener('click', this.hideComponent);
    this.setState({
      isMenuVisible: false,
    });
  };

  renderMenu() {
    const { children, contentFontClassName, actionsContainerClassName, theme } = this.props;
    const { isMenuVisible, position, positionOffset } = this.state;

    const themeTextColor = getThemeTextColor(theme);
    const themeBorderColor = getThemeBorderColor(theme);

    const actionsClassName = classNames(
      actionsContainerClassName,
      styles.actionsContainer,
      contentFontClassName,
      {
        'forum-text-color': !themeTextColor,
        'forum-card-border-color': !themeBorderColor,
        [themeTextColor]: !themeTextColor,
        [themeBorderColor]: !themeBorderColor,
      },
    );
    const triangleClassName = classNames(
      styles.triangle,
      'forum-card-border-color',
      'default-forum-card-background-color',
    );

    const actionsStyle = {};
    if (position === POSITION_RIGHT) {
      actionsStyle.transform = `translateX(${positionOffset}px)`;
    }

    if (isMenuVisible) {
      return (
        <div
          className={actionsClassName}
          style={actionsStyle}
          ref={(node) => this.setActionsContainer(node)}
          data-hook="profile-actions"
        >
          <div className={triangleClassName} />
          <div className={classNames(styles.actionsPadding, 'default-forum-card-background-color')}>
            {children}
          </div>
        </div>
      );
    }
  }

  render() {
    const { icon } = this.props;
    const { position, isMenuVisible } = this.state;

    const moreButton = (
      <Button
        className={classNames(styles.button, 'profile-actions-button')}
        isSecondary={!isMenuVisible}
        data-hook="profile-actions-button__more-button"
      >
        <MoreIcon className={isMenuVisible ? 'button-primary-icon-fill' : 'button-fill'} />
      </Button>
    );
    return (
      <span className={classNames(styles.container, styles[position])} onClick={this.handleClick}>
        {icon ? icon : moreButton}
        {this.renderMenu()}
      </span>
    );
  }
}

ProfileActionsButton.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  position: PropTypes.string,
  actionsContainerClassName: PropTypes.string,
  contentFontClassName: PropTypes.string.isRequired,
  cardBackgroundColor: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

ProfileActionsButton.POSITION_RIGHT = POSITION_RIGHT;

export default flowRight(withFontClassName, withCardBackgroundColor)(ProfileActionsButton);
