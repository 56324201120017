import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { GatewayProvider, GatewayDest } from 'react-gateway';
import AccessibilityListener from '../accessibility-listener';
// import WindowResizeListener from './ReactWindowResizeListener';
import MessageRoot from '../messages/message-root';
import ModalRoot from '../modals/modal-root';
import ErrorState from '../error-state';
import { getLocation } from '../../../common/store/location/location-selectors';
import styles from './app.scss';
import withCardBorderWidth from '../../hoc/with-card-border-width';

export class AppDesktop extends Component {
  static propTypes = {
    desktopHeader: PropTypes.node,
    main: PropTypes.node.isRequired,
    children: PropTypes.node,
    menu: PropTypes.node,
    isDebug: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    borderWidth: PropTypes.number,
  };

  render() {
    const { desktopHeader, menu, main, children, isDebug, borderWidth } = this.props;
    return (
      <GatewayProvider>
        <div className={styles.forumContainer}>
          <div
            id="content-wrapper"
            className={classNames(
              styles.app,
              styles.roundedBorders,
              'app-desktop',
              'forum-background-color'
            )}
            style={{ '--wcBorderWidth': `${borderWidth}px` }} // different border width per layout nonsense
          >
            <AccessibilityListener />
            <ModalRoot />
            <MessageRoot />
            <div data-hook="forum-content-wrapper" id="content-wrapper">
              {desktopHeader && React.cloneElement(desktopHeader, { menu })}
              {React.cloneElement(main, { children })}
            </div>
            <GatewayDest name="floating-action-button" />
            <GatewayDest name="tooltip" />
            {isDebug && <ErrorState />}
          </div>
        </div>
      </GatewayProvider>
    );
  }
}

const mapRuntimeToProps = (state) => ({
  isDebug: get(getLocation(state), 'query.debug'),
});

export default flowRight(withCardBorderWidth, connect(mapRuntimeToProps))(AppDesktop);
